<template>
  <div>
    <section class="section is-main-section">
      <title-bar :title-stack="titleStack"/>

      <card-component>
        <div class="content card-content">

          <h3>1.2.5 - March 4 2024</h3>
          <ul>
            <li>Added 2FA Support for certain users</li>
            <li>Added support for ANPR XML exports</li>
          </ul>

          <h3>1.2.4 - September 1 2023</h3>
          <ul>
            <li>Updated video viewer</li>
            <li>Whitelists can be dynamically imported</li>
            <li>Extensive logging for ANPR cameras added</li>
          </ul>

          <h3>1.2.1 - December 20 2022</h3>
          <ul>
            <li>Exception Support for V2 Towers</li>
            <li>Various bugfixes and security improvements</li>
          </ul>

          <h3>1.2.0 - November 23 2022</h3>
          <ul>
            <li>Support for TowerEye V2 Towers</li>
            <li>New UI and support for Incidents, which combines Events, Snapshots, Recording and Control-room actions.
                Already available for V2 Packages, coming soon to existing ones.</li>
            <li>When Auto-download is enabled, recordings of consecutive alerts while still triggered will be downloaded as well.</li>
            <li>Various frontend bugfixes and security improvements</li>
          </ul>

          <h3>1.1.0 - April 20 2022</h3>
          <ul>
            <li>ANPR Functionalities added</li>
          </ul>


          <h3>1.0.2 - November 25 2021</h3>
          <ul>
            <li>Person who changed an armingscheme will be mentioned in the mail/notification, as well as to whom the reminder was sent.</li>
            <li>Generator mode can be activated on a package, which will notify technicians when a generator is working in overtime.</li>
            <li>Various frontend bugfixes and security improvements</li>
          </ul>

          <h3>1.0.1 - April 5 2021</h3>
          <ul>
            <li>Unvalidated alerts are now visible as a user</li>
            <li>Various small bugfixes and security improvements</li>
          </ul>

         <h3>1.0.0 - March 23 2021</h3>
          <ul>
            <li>Release Towereye Portal - Monitor your construction site with ease.</li>
          </ul>

        </div>
      </card-component>

    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
export default {
  name: 'Changelog',
  components: { TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
        'Release Notes'
      ]
    },

  }
}
</script>
